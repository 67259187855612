import "../scss/style.scss";

import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.min.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.min.css";

require("./lib/modernizr");
require("./lib/detectizr");

const jQuery = require("jquery");
window.$ = window.jQuery = jQuery;

import "foundation-sites";

require("owl.carousel");
require("lightbox2");

var NodesManagerFunctions = function() {
  this.bodyNode = $("body");
};

NodesManagerFunctions.prototype.init = function() {
  this.getNodes();
};

NodesManagerFunctions.prototype.getNodes = function() {
  let self = this;
  $("[data-function]")
    .not(".hasBeenBound")
    .each(function() {
      let node = $(this),
        command = node.data("function"),
        options = node.data("options") || "";

      if (command != null) {
        let functions = command.split(" ");
        $.each(functions, function(i, v) {
          if (typeof self[v] == "function") {
            if (options != null && options != "") {
              if (typeof options == "string") {
                let fields = options.split(", ");
                if (typeof fields == "object" && fields != "") {
                  let data = new Function("return" + options);
                  options = data();
                } else {
                  options = "";
                }
              }

              self[v].apply(self, [node, options]);
            } else {
              self[v].apply(self, [node, options]);
            }
          } else {
            console.log("function " + v + " not found, called by ", node);
          }
        });
      } else {
        console.log("function " + command + " not found, called by ", node);
      }
      node.addClass("hasBeenBound");
    });
};

NodesManagerFunctions.prototype.createNavigation = function(node) {
  let createNavigation = {
    eNode: null,
    mainNav: null,
    navigationS: $('<div class="bb-navigationS--wrapper" />'),
    eTrigger: $(
      '<button type="button" class="button bb-navigation--btn"><span class="bb-navigation--btn__bar"></span></button>'
    )
  };

  createNavigation.init = function(node) {
    this.eNode = node;

    this.setHtml();
    this.setElements();
    this.setEvents();
  };

  createNavigation.setHtml = function() {
    this.eNode.find(".bb-menu").append(this.eTrigger);

    this.eNode.append(this.navigationS);
  };

  createNavigation.setElements = function() {
    let mainNav = this.eNode
      .find(".bb-navigation")
      .clone()
      .removeAttr("class")
      .addClass("bb-navigationS");
    this.eNode.find(".bb-navigationS--wrapper").append(mainNav);
  };

  createNavigation.setEvents = function() {
    this.eNode.find(".bb-navigation--btn").bind("click", function(e) {
      let button = $(".bb-navigation--btn");
      e.preventDefault();
      if (button.hasClass("bb-is-active")) {
        button.removeClass("bb-is-active");
      } else {
        button.addClass("bb-is-active");
      }
      $(".bb-navigationS--wrapper").toggle();
    });
  };

  createNavigation.init(node);
};

NodesManagerFunctions.prototype.lightBoxMin = function(node) {
  let lightBoxMin = {
    eNode: null
  };

  lightBoxMin.init = function(node) {
    this.eNode = node;
    this.showText();
  };

  lightBoxMin.showText = function(node) {
    if ($("html").hasClass("tablet") || $("html").hasClass("mobile")) {
      $(".bb-carpet--item").bind("click touch", function(e) {
        if (!$(this).hasClass("is-active")) {
          $(this).addClass("is-active");
          e.preventDefault();
          return false;
        }
        if (
          $(".lightboxOverlay").bind("click touch", function() {
            $(".bb-carpet")
              .find(".is-active")
              .removeClass("is-active");
          })
        );
      });
    }
  };

  lightBoxMin.init(node);
};

NodesManagerFunctions.prototype.sliderFeedback = function(node) {
  node.find(".owl-carousel").owlCarousel({
    items: 1,
    autoplay: true,
    loop: true,
    autoplayTimeout: 3000,
    autoplaySpeed: 200
  });
};

/**
 * Function for the cookie banner.
 * If you click the deny or accept button, the script will call a php script via ajax.
 * Needs:
 *  /site/plugins/
 *  /site/snippet/footer.php#22
 */
NodesManagerFunctions.prototype.cookieBanner = function(node) {
  node.find(".bb-cookie--button").on("click", function(event) {

    let ele = $(this),
      action = ele.data("action"),
      ajaxurl = ele.data("ajaxurl");

    if(action != null && ajaxurl !== "") {
      event.preventDefault();
    }
    
    if (action === "accept" && ajaxurl !== "") {
      $.ajax({
        url: ajaxurl,
        dataType: "json",
        success: function(data) {
          if(data.redirect){
            window.location.replace(data.redirect);
          }
          node.remove();
        },
        error: function(data) {
          console.log(data.statusText);
          ele.html("There was an error");
        }
      });
    } else if (action === "decline" && ajaxurl !== "") {
      $.ajax({
        url: ajaxurl,
        success: function(data) {
          node.remove();
        },
        error: function(data) {
          console.log(data.statusText);
          ele.html("There was an error");
        }
      });
    }
  });
};

let Node = new NodesManagerFunctions();
Node.init();
